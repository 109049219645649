import { routes } from '../constants';
import { CategoryPage } from '../pages/category';
import { ContactPage } from '../pages/contact';
import { DetailsPage } from '../pages/details';
import { FeedbackPage } from '../pages/feedback';
import { HomePage } from '../pages/home';
import { AdminLoginPage } from '../pages/login';
import { AdminProductPage } from '../pages/product';
import { ProductsPage } from '../pages/products';

export const publicRoutes = [
  { path: routes.home, component: HomePage, layout: true },
  { path: routes.products, component: ProductsPage, layout: true },
  { path: routes.category, component: CategoryPage, layout: true },
  { path: routes.details, component: DetailsPage, layout: true },
  { path: routes.contact, component: ContactPage, layout: true },
  { path: routes.admin, component: AdminLoginPage, layout: false },
  {
    path: routes.adminProduct,
    component: AdminProductPage,
    layout: true,
    isPrivate: true,
  },
  {
    path: routes.feedback,
    component: FeedbackPage,
    layout: true,
    isPrivate: true,
  },
];
