import * as React from 'react';

import Button from '@mui/material/Button';
import { DeleteProduct } from '../../../graphql/mutations';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';

interface DeleteDialogProps {
    open: boolean;
    onClose: () => void;
    id?: string | null;
    refetchData?:any
}

export const DeleteDialog:React.FC<DeleteDialogProps>=({
    open,
    onClose,
    id,
    refetchData
}) => {

    const [deleteProduct] = useMutation(DeleteProduct);

  const handleDelete = async () =>{
    try{
        const {data} = await deleteProduct({
            variables:{
                deleteProductId:id
            }
        })
        if(data){
            toast.success(`Xóa sản phẩm thành công`)
            onClose()
            refetchData()
        }
    }catch(error){
        toast.error(`Xóa sản phẩm thất bại ${error}`)
        onClose()
    }
  }

  return (
    <React.Fragment>
      <Dialog
      sx={{padding:'20px'}}
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontSize:'30px', color:'black', padding:'25px', lineHeight:'20px'}} id="alert-dialog-title">
          {"Bạn muốn xóa sản phẩm này ?"}
        </DialogTitle>
        <DialogActions>
          <Button variant='text' onClick={onClose}>KHÔNG</Button>
          <Button onClick={handleDelete} autoFocus>
            CÓ
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}