import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

interface Props extends PropsWithChildren {
  rootMargin?: string;
}

export const FadeInSection = (props: Props) => {
  const [isVisible, setVisible] = useState(true);
  const domRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible(entry.isIntersecting);
      },
      {
        rootMargin: props.rootMargin,
      }
    );
    observer.observe(domRef.current);
    return () => observer.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible) {
      domRef.current.classList.add('fade-in-animation');
    } else {
      domRef.current.classList.remove('fade-in-animation');
    }
  }, [isVisible]);

  return (
    <div ref={domRef} className="opacity--0">
      {props.children}
    </div>
  );
};
