import { Box, Container, CssBaseline, Toolbar } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { DrawerAdmin } from './DrawerAdmin';
import { HeaderAdmin } from './HeaderAdmin';

interface Props extends PropsWithChildren {}

export const AdminLayout = ({children}:Props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor:'white', overflowX:'hidden' }}>
      <CssBaseline />
      <HeaderAdmin toggleDrawer={toggleDrawer} />
      <DrawerAdmin open={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box
        sx={{ flexGrow: 1 }}
      >
        <Toolbar /> {/* Ensures content is not under the AppBar */}
        <Container style={{backgroundColor:'white', minHeight:'100%'}}>
          {children}
        </Container>
      </Box>
    </Box>
  );
};
