import { Box, CardActionArea, Stack } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import PopUpDetailProduct from '../popup/PopUpDetailProduct';
import Typography from '@mui/material/Typography';
import { formatVND } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const styles = {
  card: {
    background: 'transparent',
    position: 'relative',

    '& .overlay': {
      opacity: '1',
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      maxHeight: '31,7%',
      padding: '0px',
      backdropFilter: 'blur(4px)',
    },
    '& .price': {
      width: '100%',
      padding: '10px 16px',
      backgroundColor: '#181818B2',
      color: 'var(--white)',
      display: 'inline-flex',
      gap: '8px',
    },
    '& .bottom': {
      width: '100%',
      padding: '10px 16px',
      color: 'var(--white)',
      backgroundColor: '#F6EEE580',
      transition: 'padding 0.4s ease',
      opacity: '.8',
    },
    '&:hover .overlay': {
      maxHeight: '35%',
    },
    '&:hover .price': {
      color: 'var(--secondary-02)',
    },
    '&:hover .bottom': {
      maxHeight: 'calc(100% - 37.6px)',
      padding: '12px 16px',
    },
    '&:hover .zoomIn': {
      display: 'block',
    },
    '.zoomIn': {
      display: 'none',
    },
  },
};

export const ThumbnailProducts = ({ data }: { data?: any }) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  return (
    <Card
      sx={[
        styles.card,
        {
          height:
            width >= 744 ? (width > 1024 ? '315.7px' : '351.7px') : '284px',
        },
      ]}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          image={data?.image}
          alt={data?.code}
          loading="lazy"
          height={
            width >= 744 ? (width > 1024 ? '315.7px' : '351.7px') : '284px'
          }
          onClick={() => navigate(`/products/${data.type?.id}/${data.id}`)}
        />
        <CardContent className="overlay">
          <Box
            className="price"
            component="div"
            onClick={() => navigate(`/products/${data.type?.id}/${data.id}`)}
          >
            <Typography
              variant="body1"
              color="GrayText"
              sx={{ textDecoration: `${data?.priceSale !== 0 ? ' line-through' : 'interhit' }`, color:`${data?.priceSale !== 0 ? 'gray' : 'white'}` }}
              fontWeight={data?.priceSale !== 0 ? 200 : 500}
            >
              {formatVND(data?.price)} đ
            </Typography>
            {data?.priceSale !== 0 ? (<Typography variant="body1" fontWeight={500}>
              {formatVND(data?.priceSale)} đ
            </Typography>): ''}
          </Box>
          <Stack
            className="bottom"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box sx={{width:'90%'}}
              component="div"
              onClick={() => navigate(`/products/${data.type?.id}/${data.id}`)}
            >
              <Typography
                fontSize="18px"
                fontWeight={300}
                color="var(--neutral-darkGrey)"
                textTransform="uppercase"
                sx={{textOverflow:'ellipsis', overflow:'hidden',whiteSpace:'nowrap'}}
              >
                {data?.name}
              </Typography>
              <Typography
                fontSize="14px"
                fontWeight={300}
                color="text.secondary"
              >
                {data?.code}
              </Typography>
            </Box>
            <PopUpDetailProduct data={data} />
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
