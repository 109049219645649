import './layout.css';

import Footer from './Footer';
import { Header } from './Header';
import { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import { routes } from '../constants';
import { useLocation } from 'react-router-dom';

interface Props extends PropsWithChildren {}

export const MainLayout = ({ children }: Props) => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: '-100%',
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: '100%',
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'easeInOut',
    duration: 0.5,
  };

  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div>
        <Header />
        {children}
        {location.pathname !== routes.contact ? <Footer /> : null}
      </div>
    </motion.div>
  );
};