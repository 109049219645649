import { Container, Box, Typography, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Login } from '../../graphql/mutations';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export const AdminLoginPage = () => {
  //check token
  const [login] = useMutation(Login);
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const { data } = await login({
        variables: {
          email,
          password
        }
      })
      if (data.login.token) {
        toast.success('Đăng nhập thành công')
        localStorage.setItem('token',data.login.token)
        navigate('/admin/products')
      }
    } catch (error) {
      if(error){
        toast.error(`Đăng nhập thất bại ${error}`)
      }
    }
    // Here, you'd typically make an API call to your backend to authenticate the user
  };

  useEffect(()=>{
    if(localStorage.getItem('token')){
      navigate('/admin/products')
    }
  })

  return (
    <Box sx={{ backgroundColor: 'white', width: '100%', minHeight: '100vh' }}>
      <Container sx={{ width: '40%', paddingTop: 8 }}>
        <Box
          sx={{
            padding: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid var(--neutral-darkGrey)',
            borderRadius: '30px',
          }}
        >
          <Typography variant="h5" sx={{ color: 'var(--neutral-darkGrey)' }}>
            Đăng nhập
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="username"
              autoFocus
              value={email}
              sx={{
                color: 'var(--neutral-darkGrey)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--neutral-darkGrey)',
                  },
                },
                '& .MuiInputBase-input':{
                  color:'black !important'
                }
              }}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mật khẩu"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              sx={{
                color: 'var(--neutral-darkGrey)',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'var(--neutral-darkGrey)',
                  },
                },
                '& .MuiInputBase-input':{
                  color:'black !important'
                }
              }}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                backgroundColor: 'var(--primary)',
                border: '1px solid var(--primary)',
              }}
            >
              Đăng nhập
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
