export interface Product {
  id?:string;
  name: string;
  code: string;
  price: number;
  priceSale: number;
  image: string;
  description: string;
  capacity: number;
  type: TypeProduct;
  link: string;
  categories: Category[]
  radius:number;
  height:number;
}

export interface Category {
  id: string;
  name: string;
}

export interface TypeProduct {
  id: string;
  name: string;
}

export const UPLOAD_URL = 'https://api.lyglassware.com/upload'
