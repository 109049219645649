import './dialogTypeProduct.css';

import { Box, Dialog, Fade, IconButton, Typography } from '@mui/material';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import BackgroundMenu from '../assets/background-menu.jpg';
import { TransitionProps } from '@mui/material/transitions';
import { TypeProduct } from '../models/typeProduct.model';
import { capitalizeWords } from '../utils';
import { getAllTypeProduct } from '../graphql/queries';
import { images } from '../assets';
import { useQuery } from '@apollo/client';

type Props = {
  closeDrawer?: Function;
};

export type DialogTypeProductRef = {
  toggle: () => void;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

export const DialogTypeProduct = forwardRef<DialogTypeProductRef, Props>(
  (props, ref) => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const isNewArrivalsPage = searchParams.get('type');

    const { data: allTypeProducts } = useQuery(getAllTypeProduct, {
      variables: {
        page: 1,
        limit: 100,
      },
    });

    const [selectedTypeProduct, setSelectedTypeProduct] =
      useState<TypeProduct | null>(null);
    const [openProductMenu, setOpenProductMenu] = useState<boolean>(false);

    const toggleProductMenu = () => {
      setOpenProductMenu((prev) => !prev);
    };

    useImperativeHandle(ref, () => ({
      toggle: () => {
        toggleProductMenu();
      },
    }));

    const handleSelectTypeProduct = (type: TypeProduct) => {
      setSelectedTypeProduct(type);
      navigate(`/products/${type.id}`);
      props.closeDrawer && props.closeDrawer();
      setOpenProductMenu(false);
    };

    useEffect(() => {
      if (
        Object.keys(params).length === 0 ||
        !allTypeProducts ||
        allTypeProducts.queryAllTypeProductPagination?.typeProducts?.length ===
          0 ||
        isNewArrivalsPage
      ) {
        return;
      }
      const typeProduct: TypeProduct =
        allTypeProducts.queryAllTypeProductPagination?.typeProducts.find(
          (product: TypeProduct) => product.id === params.type_id
        );
      setSelectedTypeProduct(typeProduct);
    }, [params, isNewArrivalsPage, allTypeProducts]);

    return (
      <Dialog
        fullScreen
        open={openProductMenu}
        TransitionComponent={Transition}
      >
        <Box className="dialog-container">
          <Box className="background-menu">
            <img
              style={{
                width: '100%',
                minHeight: '100vh',
                height: '100%',
                objectFit: 'cover',
              }}
              src={BackgroundMenu}
              alt="background menu"
            />
          </Box>
          <Box className="content">
            <Box className="close-icon" onClick={toggleProductMenu}>
              <IconButton>
                <img src={images.close} alt="close-dialog" />
              </IconButton>
            </Box>
            <Box className="content-menu">
              <Typography variant="h2" className="title menu-heading">
                Collections
              </Typography>
              <Box className="menu-container">
                {allTypeProducts?.queryAllTypeProductPagination?.typeProducts?.map(
                  (category: any, idx: number) => (
                    <Typography
                      key={idx}
                      variant="h4"
                      className={`title menu-item ${
                        selectedTypeProduct?.name === category.name
                          ? 'active'
                          : ''
                      }`}
                      onClick={() => handleSelectTypeProduct(category)}
                    >
                      {capitalizeWords(category.name)}
                    </Typography>
                  )
                )}
                <Typography
                  variant="h4"
                  className="title menu-item"
                  onClick={() => navigate('/products')}
                >
                  See All
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    );
  }
);
