import './style.css';

import {
  Box,
  Container,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { BreadCrumbComponent } from '../../components/breadcrumb';
import { GoBackButton } from '../../components/button/GoBackButton';
import { MainLayout } from '../../layout/MainLayout';
import PopUpSubmitEmail from '../../components/popup/PopUpSubmitEmail';
import { SubmitFeedback } from '../../graphql/mutations';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { navItems } from '../../constants';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const ContactPage = () => {
  const isUpDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const { width } = useWindowDimensions();

  const [inputEmail, setInputEmail] = useState('');
  const [inputFeedback, setInputFeedback] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [open, setOpen] = useState(false);

  const [sendFeedback] = useMutation(SubmitFeedback);

  const handleSubmit = async () => {
    try {
      // Gọi mutation để gửi feedback
      const { data } = await sendFeedback({
        variables: {
          input: {
            email: inputEmail,
            feedback: inputFeedback,
          },
        },
      });

      if (data.submitFeedback.success) {
        setOpen(true);
        // Đặt lại giá trị của input sau khi gửi
        setInputEmail('');
        setInputFeedback('');
      }
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error('Error sending feedback:', error);
    }
  };

  return (
    <MainLayout>
      <Container className="contact-top">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          sx={{ cursor: 'pointer' }}
        >
          <Box mr={width >= 744 ? 4 : 2}>
            <GoBackButton />
          </Box>
          <BreadCrumbComponent />
        </Stack>
        <Box mx={3.5}>
          <Typography
            variant="h1"
            textAlign="left"
            className="title contact-heading"
          >
            Contact
          </Typography>
        </Box>
      </Container>
      <Container id="contact" className="contact-sent-message">
        <Stack
          component={Container}
          maxWidth={isDownTablet ? '320px' : '600px'}
          direction="column"
          justifyContent="center"
          gap={2}
          sx={{ padding: 0 }}
        >
          <Typography textAlign="center" className="subTitle heading" mb={1.75}>
            Send us a message
          </Typography>
          <TextField
            placeholder="Enter your email"
            value={inputEmail}
            onChange={(e) => {
              setInputEmail(e.target.value);
              // Kiểm tra định dạng email
              const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e.target.value);
              setIsValidEmail(isValid);
            }}
            error={!isValidEmail}
            helperText={
              !isValidEmail ? 'Please enter a valid email address' : ''
            }
          ></TextField>
          <TextField
            multiline
            rows={10}
            value={inputFeedback}
            onChange={(e) => setInputFeedback(e.target.value)}
            placeholder="Say Hello or give us any feedback"
          />
          <Stack direction="row" flexWrap="wrap" alignItems="center" gap={1}>
            <Link
              href={'https://www.instagram.com/ly.glassware/'}
              target="_blank"
              sx={{ padding: 0 }}
            >
              <Box className="box-social">
                <img
                  src={images.instagram}
                  width={32}
                  height={32}
                  alt="instagram"
                />
              </Box>
            </Link>
            <Link
              href={'https://www.facebook.com/ly.glassware/'}
              target="_blank"
              sx={{ padding: 0 }}
            >
              <Box className="box-social">
                <img
                  src={images.messenger}
                  width={32}
                  height={32}
                  alt="facebook"
                />
              </Box>
            </Link>
            <PopUpSubmitEmail
              isContact={true}
              handleSubmit={handleSubmit}
              open={open}
              setOpen={setOpen}
            />
          </Stack>
        </Stack>
      </Container>

      {/* <Box className="footer">
        <Grid container spacing={4}>
          <Grid item desktop={4}>
            <Stack direction={'column'} gap={'16px'}>
              <Typography fontWeight={700}>Quick links</Typography>
              <Typography fontWeight={200}>Terms of Service</Typography>
              <Typography fontWeight={200}>Privacy Policy</Typography>
            </Stack>
          </Grid>
          <Grid item desktop="auto">
            <Stack direction={'column'} gap={'16px'}>
              <Typography fontWeight={700}>Contact information</Typography>
              <Typography fontWeight={200}>
                Address: An Phu Ward, District 2, HCMC
              </Typography>
              <Typography fontWeight={200}>Phone: +84 123 456 789</Typography>
              <Typography fontWeight={200}>
                Email: Ly.glassware@gmail.com
              </Typography>
              <Typography fontWeight={200}>
                Stay in touch on our socials, or reach out to direct message/
                email if you need assistance.
              </Typography>

              <Stack direction={'row'} gap={1}>
                <Link
                  href={'https://www.facebook.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.facebook} alt="" /> 
                </Link>
                <Link
                  href={'mailto:glassware@gmail.com'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.mail} alt="" /> 
                </Link>
                <Link
                  href={'https://www.instagram.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.instagram} alt="" /> 
                </Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box> */}

      <Box className="footer">
        <Grid container spacing={4}>
          <Grid item mobile={12} tablet={3} desktop={2.25} oversize={1.75}>
            <Stack direction="column" gap={2}>
              <Typography fontWeight={700}>Quick links</Typography>
              <Typography fontWeight={200}>Terms of Service</Typography>
              <Typography fontWeight={200}>Privacy Policy</Typography>
            </Stack>
          </Grid>
          <Grid item mobile={12} tablet={3} desktop={2.25} oversize={1.75}>
            <Stack direction="column" gap={2}>
              <Typography fontWeight={700}>Features</Typography>
              {navItems.map((item, idx) => (
                <RouterLink
                  key={idx}
                  to={item.path}
                  style={{ color: 'var(--white)' }}
                >
                  <Typography key={idx} fontWeight={200}>
                    {item.name}
                  </Typography>
                </RouterLink>
              ))}
            </Stack>
          </Grid>
          <Grid item mobile={12} tablet={6} desktop={4} oversize={3.5}>
            <Stack
              sx={{ maxWidth: isUpDesktop ? '340px' : '100%' }}
              direction="column"
              gap={2}
            >
              <Typography fontWeight={700}>Contact information</Typography>
              <Typography fontWeight={200}>
                Address: An Phu Ward, District 2, HCMC
              </Typography>
              <Typography fontWeight={200}>Phone: +84 353 311 099</Typography>
              <Typography fontWeight={200}>
                Email: Ly.glassware@gmail.com
              </Typography>
              <Typography fontWeight={200}>
                Stay in touch on our socials, or reach out to direct message/
                email if you need assistance.
              </Typography>

              <Stack direction={'row'} gap={1}>
                <Link
                  href={'https://www.facebook.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.facebook} alt="" />
                </Link>
                <Link
                  href={'mailto:Ly.glassware@gmail.com'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.mail} alt="" />
                </Link>
                <Link
                  href={'https://www.instagram.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.instagram} alt="" />
                </Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="copyright">
        <Stack direction="row" gap={0.5}>
          <Typography fontWeight={200}>Copyright © 2024</Typography>
          <Typography fontWeight={600}>lyglassware.com</Typography>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Typography fontWeight={200}>Built by</Typography>
          <Link
            href="https://www.behance.net/trnlu1"
            target="_blank"
            sx={{ color: 'var(--white)', textDecoration: 'none' }}
          >
            <Typography fontWeight={600}>LuuTran</Typography>
          </Link>
        </Stack>
      </Box>
    </MainLayout>
  );
};
