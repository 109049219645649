import './style.css';

import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import { ExploreMoreButton } from '../../components/button/ExploreMoreButton';
import ThumbnailCollections from '../../components/thumbnails/Collections';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { useNavigate } from 'react-router-dom';
import { FadeInSection } from '../../components/animations/FadeInSection';

export const CollectionSection = ({
  data,
  handleIncreaseLimit,
  total,
}: {
  data: Array<any>;
  handleIncreaseLimit: Function;
  total: number;
}) => {
  const navigate = useNavigate();
  const isDownTablet = useMediaQuery(theme.breakpoints.down('desktop'));

  const handleExploreMore = () => {
    navigate(`/products`);
  };

  return (
    <FadeInSection>
      <Box className="collection-section">
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
          mx={isDownTablet ? '32px' : 0}
        >
          <Box
            component="div"
            style={{ cursor: 'pointer' }}
            sx={{
              cursor: 'pointer',
              '&:hover': { color: 'var(--secondary-01)' },
            }}
            onClick={handleExploreMore}
          >
            <Typography
              fontSize={(isDownTablet ? '48px' : '57px') + ' !important'}
              className="title"
            >
              Collections
            </Typography>
          </Box>
          {!isDownTablet && (
            <ExploreMoreButton
              label="See all products"
              onClick={handleExploreMore}
            />
          )}
        </Stack>
        <Grid
          mx="auto"
          maxWidth="oversize"
          container
          rowSpacing={0.5}
          columnSpacing={0.5}
        >
          {data?.map((item: any, idx: number) => (
            <Grid key={idx} item mobile={6} tablet={6} desktop={6} oversize={4}>
              <ThumbnailCollections data={item} />
            </Grid>
          ))}
          <Box
            textAlign={'center'}
            my={2}
            width={'100%'}
            display={data?.length >= total ? 'none' : 'block'}
          >
            <Box onClick={() => handleIncreaseLimit()}>
              <img
                src={images.arrowDown}
                alt=""
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Box>
        </Grid>
        <Box width={'100%'} textAlign={'center'} mt={'16px'}>
          {isDownTablet && (
            <ExploreMoreButton
              label="See all products"
              onClick={handleExploreMore}
            />
          )}
        </Box>
      </Box>
    </FadeInSection>
  );
};
