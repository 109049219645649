import PopUpInfoProduct from '../popup/PopUpInfoProduct';

interface Props {
  data: any;
  listData: Array<any>;
}

export default function ThumbnailGallery({ data, listData }: Props) {
  return <PopUpInfoProduct data={data} listData={listData} />;
}
