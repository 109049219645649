import { Stack, Typography } from '@mui/material';
import React from 'react';
import { images } from '../../assets';
import { useNavigate } from 'react-router-dom';

export const GoBackButton = () => {
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Stack direction="row" onClick={goBack}>
      <img src={images.chevronLeft} alt="" />
      <Typography>Back</Typography>
    </Stack>
  );
};
