import './thumbnailCollections.css';

import { CardActionArea, useMediaQuery } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { theme } from '../../themes/Theme';
import { useNavigate } from 'react-router-dom';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export default function ThumbnailCollections({ data }: { data?: any }) {
  const navigate = useNavigate();
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const { width } = useWindowDimensions();

  return (
    <Card
      className="thumbnail-collection"
      sx={{ height: isDownTablet ? 284 : 524 }}
      onClick={() => navigate(`/products/${data?.id}`, { replace: true })}
    >
      <CardActionArea sx={{ height: '100%' }}>
        <CardMedia
          component="img"
          image={data?.image}
          loading="lazy"
          sx={{ height: '100%' }}
        />
        <CardContent className="overlay">
          <Typography variant="h5" textTransform="capitalize" className="title">
            {data?.name.toUpperCase()}
          </Typography>
          {width > 430 ? (
            <Typography variant="body1" className="description">
              {data?.description}
            </Typography>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
