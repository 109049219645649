import './style.css';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from '@mui/material';

import { formatVND } from '../../utils';
import { images } from '../../assets';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const styles = {
  card: {
    position: 'relative',
    border: '1px solid var(--primary)',
    // '.info': {
    //   color: 'var(--primary)',
    //   backgroundColor: 'var(--secondary-01)',
    //   height: '160px',
    //   '.details': {
    //     display: 'block',
    //   },
    // },
    // '& .MuiCardActionArea-root': { cursor: 'default' },
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    gap: '16px',
    width: '100%',
    margin: '-1px',
    backgroundColor: 'var(--white-secondary)',
  },
};

const PopUpDetailProduct = ({ data }: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        className="zoomIn"
        onClick={handleClickOpen}
        sx={{ cursor: 'pointer' }}
      >
        <img src={images.zoomIn} alt="" />
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': { borderRadius: '0px', overflowY: 'hidden' },
        }}
      >
        <DialogContent
          sx={{
            maxWidth: '600px',
            padding: '0',
            maxHeight: '512px',
            backgroundColor: 'transparent',
          }}
        >
          <Card sx={styles.card}>
            <Box className="popup__header">
              <IconButton aria-label="close" onClick={handleClose}>
                <Box component="img" width="24px" src={images?.close} alt="" />
              </IconButton>
            </Box>
            <CardActionArea>
              <CardMedia
                component="img"
                height="100%"
                image={data?.image}
                style={{ maxHeight: '509px' }}
                loading="lazy"
                onClick={() =>
                  navigate(`/products/${data.type?.id}/${data.id}`)
                }
              />
              <CardContent
                sx={styles.overlay}
                onClick={() =>
                  navigate(`/products/${data.type?.id}/${data.id}`)
                }
              >
                <Box sx={{width:'100%'}}>
                  <Typography
                    variant="h4"
                    className="title"
                    color="var(--primary)"
                    mb={0.5}
                    sx={{textOverflow:'ellipsis', overflow:'hidden'}}
                  >
                    {data?.name}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="var(--neutral-darkGrey-70)"
                  >
                    {data?.code}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color="GrayText"
                    sx={{ textDecoration: `${data?.priceSale !== 0 ? ' line-through' : 'interhit'}`, color:`${data?.priceSale !== 0 ? 'gray' : 'var(--primary)'}` }}
                    fontWeight={data?.priceSale !== 0 ? 200 : 500 }
                  >
                    {formatVND(data?.price)} đ
                  </Typography>
                  {data?.priceSale !== 0 ? (<Typography variant="body1" color="var(--primary)" fontWeight={500}>
                    {formatVND(data?.priceSale)} đ
                  </Typography>) : ''}
                </Box>
                <Box>
                  <Typography variant="body1" color="text.secondary">
                    Volume {data?.capacity}ml
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    Dimension: H{data?.height}mm x D{data?.radius}mm
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopUpDetailProduct;
