import arrowDown from './arrow-down.svg';
import arrowLarge from './arrow-large.svg';
import arrowLeft from './arrow-left.svg';
import arrowNextBlack from './arrow-next-black.svg';
import arrowPrevBlack from './arrow-prev-black.svg';
import arrowRight from './arrow-right.svg';
import bgInfo from './bg-info.jpeg';
import chevronLeft from './chevronLeft.svg';
import close from './close.svg';
import facebook from './facebook.svg';
import instagram from './instagram.svg';
import logo from './logo.svg';
import mail from './mail.svg';
import messenger from './messenger.svg';
import plus from './plus.svg';
import product from './product.jpg';
import tick from './tick.svg';
import zoomIn from './zoom-in.svg';

export const images = {
  arrowLarge: arrowLarge,
  plus: plus,
  product: product,
  bgInfo: bgInfo,
  zoomIn: zoomIn,
  close: close,
  chevronLeft: chevronLeft,
  logo: logo,
  mail: mail,
  facebook: facebook,
  instagram: instagram,
  messenger: messenger,
  arrowRight: arrowRight,
  arrowLeft: arrowLeft,
  tick: tick,
  arrowDown: arrowDown,
  arrowNextBlack: arrowNextBlack,
  arrowPrevBlack: arrowPrevBlack
};
