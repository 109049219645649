import {
  Box,
  Grid,
  Stack,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { images } from '../assets';
import PopUpSubmitEmail from '../components/popup/PopUpSubmitEmail';
import { navItems } from '../constants';
import { SubmitFeedback } from '../graphql/mutations';
import { theme } from '../themes/Theme';

const Footer = () => {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const isUpDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const [inputEmail, setInputEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [open, setOpen] = useState(false);

  const [sendFeedback] = useMutation(SubmitFeedback);

  const handleSubmit = async () => {
    try {
      // Gọi mutation để gửi feedback
      const { data } = await sendFeedback({
        variables: {
          input: {
            email: inputEmail,
          },
        },
      });

      if (data.submitFeedback.success) {
        setOpen(true);
        // Đặt lại giá trị của input sau khi gửi
        setInputEmail('');
      }
    } catch (error) {
      // Xử lý lỗi nếu có
      console.error('Error sending feedback:', error);
    }
  };
  return (
    <Box>
      <Box
        sx={{
          my: isDownTablet ? '100px' : '200px',
          mx: 'auto',
        }}
        className="stay-up"
      >
        <Stack
          direction="column"
          gap={'30px'}
          sx={{
            maxWidth: isDownTablet ? '320px' : '560px',
          }}
        >
          <Typography className="subTitle heading">Stay up to date</Typography>
          <Stack direction="row" spacing={1}>
            <TextField
              placeholder="Enter your email"
              value={inputEmail}
              onChange={(e) => {
                setInputEmail(e.target.value);
                // Kiểm tra định dạng email
                const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                  e.target.value
                );
                setIsValidEmail(isValid);
              }}
              error={!isValidEmail}
              helperText={
                !isValidEmail ? 'Please enter a valid email address' : ''
              }
              InputProps={{
                sx: {
                  height: 45,
                },
              }}
            />
            <PopUpSubmitEmail
              handleSubmit={handleSubmit}
              open={open}
              setOpen={setOpen}
            />
          </Stack>
          <Typography color={'rgba(255, 255, 255, 0.6)'}>
            Join our newletter or follow us on{' '}
            <Typography component="span" fontWeight={600} color={'white'}>
              Instagram
            </Typography>{' '}
            for the latest information
          </Typography>
        </Stack>
      </Box>

      <Box className="footer">
        <Grid container spacing={4}>
          <Grid item mobile={12} tablet={3} desktop={2.25} oversize={1.75}>
            <Stack direction="column" gap={2}>
              <Typography fontWeight={700}>Quick links</Typography>
              <Typography fontWeight={200}>Terms of Service</Typography>
              <Typography fontWeight={200}>Privacy Policy</Typography>
            </Stack>
          </Grid>
          <Grid item mobile={12} tablet={3} desktop={2.25} oversize={1.75}>
            <Stack direction="column" gap={2}>
              <Typography fontWeight={700}>Features</Typography>
              {navItems.map((item, idx) => (
                <RouterLink
                  key={idx}
                  to={item.path}
                  style={{ color: 'var(--white)' }}
                >
                  <Typography key={idx} fontWeight={200}>
                    {item.name}
                  </Typography>
                </RouterLink>
              ))}
            </Stack>
          </Grid>
          <Grid item mobile={12} tablet={6} desktop={4} oversize={3.5}>
            <Stack
              sx={{ maxWidth: isUpDesktop ? '340px' : '100%' }}
              direction="column"
              gap={2}
            >
              <Typography fontWeight={700}>Contact information</Typography>
              <Typography fontWeight={200}>
                Address: An Phu Ward, District 2, HCMC
              </Typography>
              <Typography fontWeight={200}>Phone: +84 353 311 099</Typography>
              <Typography fontWeight={200}>
                Email: Ly.glassware@gmail.com
              </Typography>
              <Typography fontWeight={200}>
                Stay in touch on our socials, or reach out to direct message/
                email if you need assistance.
              </Typography>

              <Stack direction={'row'} gap={1}>
                <Link
                  href={'https://www.facebook.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.facebook} alt="" />
                </Link>
                <Link
                  href={'mailto:Ly.glassware@gmail.com'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.mail} alt="" />
                </Link>
                <Link
                  href={'https://www.instagram.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.instagram} alt="" />
                </Link>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Box className="copyright">
        <Stack direction="row" gap={0.5}>
          <Typography fontWeight={200}>Copyright © 2024</Typography>
          <Typography fontWeight={600}>lyglassware.com</Typography>
        </Stack>
        <Stack direction="row" gap={0.5}>
          <Typography fontWeight={200}>Built by</Typography>
          <Link
            href="https://www.behance.net/trnlu1"
            target="_blank"
            sx={{ color: 'var(--white)', textDecoration: 'none' }}
          >
            <Typography fontWeight={600}>LuuTran</Typography>
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};

export default Footer;
