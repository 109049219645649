import './style.css';

import {
  Box,
  Container,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { BreadCrumbComponent } from '../../components/breadcrumb';
import { ButtonComponent } from '../../components/button';
import { GoBackButton } from '../../components/button/GoBackButton';
import { MainLayout } from '../../layout/MainLayout';
import { NewArrivalsSection } from '../home/NewArrivalsSection';
import { formatVND } from '../../utils';
import { getProductDetails } from '../../graphql/queries';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { useQuery } from '@apollo/client';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const DetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isDownDesktop = useMediaQuery(theme.breakpoints.down('desktop'));
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const isDownMini = useMediaQuery(theme.breakpoints.down('mini'))

  const [dataDetail, setDataDetail] = useState<any>();
  const [dataNewArrivals, setDataNewArrivals] = useState();
  const [dataRecommend, setDataRecommend] = useState();

  const { data } = useQuery(getProductDetails, {
    variables: {
      page: 1,
      limit: 10,
      typeProduct: params?.type_id,
      productId: params?.product_id,
    },
  });

  useEffect(() => {
    if (!data) return;
    if (data?.getProductById) {
      setDataDetail(data?.getProductById);
    }
    if (data?.getAllNewArrivals?.products?.length > 0) {
      setDataNewArrivals(data?.getAllNewArrivals?.products);
    }
    if (data?.getAllProducts?.products?.length > 0) {
      setDataRecommend(data?.getAllProducts?.products);
    }
  }, [data]);

  return (
    <MainLayout>
      <Container className="details-top">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          sx={{ cursor: 'pointer', gap: '8px' }}
        >
          {isDownMini ? "" : (<Box mr={width >= 744 ? 4 : 2}>
            <GoBackButton />
          </Box>)}
          <BreadCrumbComponent
            externalPath={[
              'products',
              dataDetail?.type?.name,
              dataDetail?.code,
            ]}
          />
        </Stack>
      </Container>
      <Stack
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        gap={isDownDesktop ? '100px' : 25}
      >
        <Box className="details-wrapper">
          <Box className="image-container">
            <img
              src={dataDetail?.image}
              // src='https://i.pinimg.com/236x/1b/1e/ff/1b1eff73f5c3d8b56a284f8a3aac59a5.jpg'
              width={'100%'}
              height={isDownDesktop ? '573px' : '800px'}
              alt="product"
              style={{ objectFit: 'contain' }}
            />
          </Box>
          <Box
            px={3}
            className="details-container"
            height={!isDownDesktop ? '800px' : 'auto'}
            sx={{ borderBottom: !isDownDesktop ? '1px solid white' : 'none' }}
          >
            <Typography className="details-name">
              {dataDetail?.name} {dataDetail?.code}
            </Typography>
            <Stack direction={'row'} gap={2} alignItems={'center'}>
              <Typography
                color={dataDetail?.priceSale !== 0 ? 'white' : '#e33434'}
                fontWeight={dataDetail?.priceSale !== 0 ? 300 : 500}
                sx={{
                  textDecoration: `${dataDetail?.priceSale !== 0 ? 'line-through' : 'interhit'}`,
                  opacity: `${dataDetail?.priceSale !== 0 ? '.8' : '1'}`,
                  fontSize: (isDownDesktop ? '32px' : '40px') + '!important',
                }}
              >
                {formatVND(dataDetail?.price)} đ
              </Typography>
              {dataDetail?.priceSale !== 0 ? (<Typography
                className="details-price"
                sx={{
                  fontSize: (isDownDesktop ? '32px' : '40px') + '!important',
                }}
              >
                {formatVND(dataDetail?.priceSale)} đ
              </Typography>) : ''}
            </Stack>
            <Box className="details-specs">
              <Typography>Volume {dataDetail?.capacity}ml</Typography>
              <Typography>Diameter {dataDetail?.radius}mm</Typography>
              <Typography>
                Height {dataDetail?.height}mm
              </Typography>
            </Box>
            <Typography className="details-description">
              {dataDetail?.description}
            </Typography>
            <Typography className="details-note">
              * We currently do not offer direct purchases from the website.
              Please message Ly on <b>Instagram/Messenger</b> directly to
              complete your order. Many thanks your support.
            </Typography>
            <Stack
              direction="row"
              flexWrap="wrap"
              alignItems="center"
              gap={1}
              mt={2}
            >
              <Link
                href={'https://www.instagram.com/ly.glassware/'}
                target="_blank"
                sx={{ padding: 0 }}
              >
                <Box className="box-social">
                  <img
                    src={images.instagram}
                    width={32}
                    height={32}
                    alt="instagram"
                  />
                </Box>
              </Link>
              <Link
                href={'https://www.facebook.com/ly.glassware/'}
                target="_blank"
                sx={{ padding: 0 }}
              >
                <Box className="box-social">
                  <img
                    src={images.messenger}
                    width={32}
                    height={32}
                    alt="facebook"
                  />
                </Box>
              </Link>
              <ButtonComponent
                label={
                  <Typography fontSize={isDownDesktop ? 16 : 24}>
                    OTHER PRODUCTS
                  </Typography>
                }
                isMobile={isDownTablet}
                height={52}
                onClick={() => navigate('/products')}
              />
            </Stack>
          </Box>
        </Box>
        <NewArrivalsSection data={dataRecommend} title={'You May Also Like'} />
        <NewArrivalsSection data={dataNewArrivals} />
      </Stack>
    </MainLayout>
  );
};
