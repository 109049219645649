import './style.css';

import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { ExploreMoreButton } from '../../components/button/ExploreMoreButton';
import { FadeInSection } from '../../components/animations/FadeInSection';
import Slider from 'react-slick';
import { ThumbnailNewArrivals } from '../../components/thumbnails/NewArrivals';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { useNavigate } from 'react-router-dom';

function ButtonNextArrow(props) {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const { onClick } = props;
  return (
    <Stack
      className={'bg-arrow'}
      sx={{
        right: isDownTablet ? '0px' : '-80px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
      }}
      onClick={onClick}
    >
      <img src={images.arrowRight} alt="" className="icon-white" />
      <img src={images.arrowNextBlack} alt="" className="icon-hover" />
    </Stack>
  );
}

function ButtonPrevArrow(props) {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('desktop'));
  const { onClick } = props;
  return (
    <Stack
      className={'bg-arrow'}
      sx={{
        left: isDownTablet ? '0px' : '-80px',
        top: '50%',
        transform: 'translateY(-50%)',
        position: 'absolute',
      }}
      onClick={onClick}
    >
      <img src={images.arrowLeft} alt="" className="icon-white" />
      <img src={images.arrowPrevBlack} alt="" className="icon-hover" />
    </Stack>
  );
}

export const NewArrivalsSection = ({
  title = 'New Arrivals',
  data,
}: {
  title?: string;
  data?: any;
}) => {
  const navigate = useNavigate();

  const isDownTablet = useMediaQuery(theme.breakpoints.down('desktop'));

  const settings = {
    dots: false,
    speed: 500,
    initialSlide: 0,
    centerPadding: '4px',
    infinite: data?.length > 5 ? true : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <ButtonNextArrow />,
    prevArrow: <ButtonPrevArrow />,
    responsive: title && [
      {
        breakpoint: 1024,
        settings: {
          infinite: data?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 430,
        settings: {
          infinite: data?.length > 2 ? true : false,
        },
      },
    ],
  };

  const handleExploreMore = () => {
    navigate(`/products?type=new-arrivals`);
  };

  return (
    <Box className="collection-section">
      <Container sx={{ px: '0px !important' }} maxWidth="oversize">
        <Stack
          direction="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
          mb={4}
          mx={isDownTablet ? '32px' : 0}
        >
          <Box
            component="div"
            style={{ cursor: 'pointer' }}
            sx={{
              cursor: 'pointer',
              '&:hover': { color: 'var(--secondary-01)' },
            }}
            onClick={handleExploreMore}
          >
            <Typography
              fontSize={(isDownTablet ? '48px' : '57px') + ' !important'}
              className="title"
            >
              {title}
            </Typography>
          </Box>
          {!isDownTablet && (
            <ExploreMoreButton
              label="Explore more"
              onClick={handleExploreMore}
            />
          )}
        </Stack>
      </Container>

      <div>
        <Slider {...settings}>
          {data?.map((item, index) => (
            <Box key={index} px={0.25}>
              <ThumbnailNewArrivals key={index} {...item} />
            </Box>
          ))}
        </Slider>
      </div>
      <Box width="100%" textAlign="center" mt={2}>
        {isDownTablet && (
          <ExploreMoreButton label="Explore more" onClick={handleExploreMore} />
        )}
      </Box>
    </Box>
  );
};
