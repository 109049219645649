import { Breadcrumbs, Typography } from '@mui/material';
import React from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { capitalizeWords } from '../../utils';

interface Props {
  externalPath?: Array<string>;
}

export const BreadCrumbComponent = ({ externalPath }: Props) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const pathnames = location.pathname.split('/').filter((x) => x);
  const isNewArrivalsPage = searchParams.get('type');

  return (
    <Breadcrumbs>
      <Link to="/">
        <Typography
          variant="body1"
          color="text.primary"
          sx={{
            transition: 'opacity 0.3s ease-in-out',
            '&:hover': { opacity: 0.4 },
          }}
        >
          Home
        </Typography>
      </Link>
      {externalPath
        ? externalPath.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            return (
              <Link
                key={index}
                to={
                  isNewArrivalsPage === 'new-arrivals'
                    ? `${routeTo}?type=new-arrivals`
                    : routeTo
                }
              >
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{
                    transition: 'opacity 0.3s ease-in-out',
                    '&:hover': { opacity: 0.4 },
                  }}
                >
                  {capitalizeWords(name)}
                </Typography>
              </Link>
            );
          })
        : pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
            return (
              <Link key={index} to={routeTo}>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{
                    transition: 'opacity 0.3s ease-in-out',
                    '&:hover': { opacity: 0.4 },
                  }}
                >
                  {capitalizeWords(name)}
                </Typography>
              </Link>
            );
          })}
    </Breadcrumbs>
  );
};
