import './style.css';

import {
  Box,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { ButtonComponent } from '../button';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';

const styles = {
  card: {
    zIndex: 10,
    height: '100%',
    width: '100%',
    position: 'relative',
    background: '#21212199',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

const PopUpSubmitEmail = ({ handleSubmit, open, setOpen, isContact }: any) => {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const handleClickOpen = () => {
    handleSubmit();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {isContact ? (
        <ButtonComponent
          onClick={handleSubmit}
          label={
            <Typography
              sx={{
                fontSize: (!isDownTablet ? '24px' : '16px') + ' !important',
              }}
            >
              Send
            </Typography>
          }
          height={52}
        />
      ) : (
        <Box sx={{ flex: 1 }}>
          <ButtonComponent
            label="Submit"
            size="medium"
            onClick={handleClickOpen}
          />
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '0px',
            background: '#21212199',
            width: '600px',
            height: '400px',
            overflow: 'hidden',
          },
        }}
      >
        <DialogContent
          sx={{
            position: 'relative',
            maxWidth: 600,
            height: 400,
            padding: 0,
            backdropFilter: 'blur(8px)',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              zIndex: 10,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <Box className="popup__header">
              <IconButton aria-label="close" onClick={handleClose}>
                <Box component="img" width="24px" src={images?.close} alt="" />
              </IconButton>
            </Box>
            <Card sx={styles.card}>
              <img src={images.tick} alt="" />
              <Typography fontSize={'32px'}>
                Your email’s been submitted successfully
              </Typography>
            </Card>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopUpSubmitEmail;
