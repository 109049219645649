import { gql } from '@apollo/client';

export const SubmitFeedback = gql`
  mutation submitFeedback($input: FeedbackInput) {
    submitFeedback(input: $input) {
      success
      message
    }
  }
`;

export const CreateProduct = gql`
  mutation createProduct($input: ProductInput!) {
    createProduct(input: $input) {
      id
      name
      code
      priceSale
      link
      image
      description
      capacity
      price
      height
      radius
    }
  }
`;

export const UpdateProduct = gql`
  mutation UpdateProduct($updateProductId: ID!, $input: ProductInput!) {
    updateProduct(id: $updateProductId, input: $input) {
      id
      name
      code
      priceSale
      link
      image
      description
      capacity
      price
      height
      radius
    }
  }
`;

export const DeleteProduct = gql`
  mutation Mutation($deleteProductId: ID!) {
    deleteProduct(id: $deleteProductId) {
      id
      name
      code
      priceSale
      link
      image
      description
      capacity
      price
    }
  }
`;

export const Login = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;
