import { Container, Paper } from "@mui/material"
import { AdminLayout } from "../../layout/AdminLayout"
import { useQuery } from '@apollo/client';
import { GetFeedBacks } from "../../graphql/queries";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { format } from 'date-fns';
import { formatDate } from "../../utils/formatDate";

export const FeedbackPage = () => {
    const { loading, error, data, refetch } = useQuery(GetFeedBacks, {
        variables: { page: 1, limit: 999 }, // Thay đổi giá trị của page và limit nếu cần
    });

    const [feedbacks, setFeedBacks] = useState<any | null>([])

    useEffect(() => {
        if (data) {
            setFeedBacks(data?.getFeedbacks.feedbacks)
        }
    })

    const columns: GridColDef[] = [
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'feedback', headerName: 'Nội dung feedback', flex: 1 },
        {
            field: 'createdAt', headerName: 'Ngày gửi feedback', flex: 1,
            renderCell: (params) => {
                // If using date-fns
                const formattedDate = formatDate(params.value)
                return <span>{formattedDate}</span>;
            }
        },
    ];

    if (loading) return <div>Loading ...</div>
    return (
        <AdminLayout>
            <Container sx={{ padding: '20px' }}>
                <Paper>
                    {feedbacks ? (<DataGrid
                        pagination
                        rows={feedbacks}
                        columns={columns}
                        getRowId={(row) => row.id}
                        initialState={{

                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[10]}
                        sx={{
                            height: '100%', // Full height
                            width: '100%', // Full width
                            '& .MuiDataGrid-cell': {
                                color: 'black', // Text color for cells
                            },
                            '& .MuiTablePagination-root': {
                                color: 'black',
                            },
                            '& .MuiDataGrid-columnHeaders': {
                                backgroundColor: '#910000', // Background color for header
                                color: 'white', // Text color for header
                            },
                            '& .MuiDataGrid-columnSeparator--sideRight': {
                                backgroundColor: '#910000',
                            },
                            '.MuiDataGrid-virtualScroller': {
                                height: '100% !important', // Ensure virtual scroller takes full height
                            }
                        }}
                    />): (<div style={{ height: '100%', display: 'flex', color: "black", padding: '20px', alignItems: 'center', justifyContent: 'center' }}>Chưa có feedback nào</div>)}
                </Paper>
            </Container>

        </AdminLayout>
    )
}