import './style.css';

import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { getAllTypeProduct, getDataProduct } from '../../graphql/queries';
import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { BreadCrumbComponent } from '../../components/breadcrumb';
import { GoBackButton } from '../../components/button/GoBackButton';
import { MainLayout } from '../../layout/MainLayout';
import { ThumbnailProducts } from '../../components/thumbnails/Products';
import { capitalizeWords } from '../../utils';
import { images } from '../../assets';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

type TypeProduct = {
  id: string;
  name: string;
};

export const CategoryPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [searchParams] = useSearchParams();
  const typeProductParams = useParams();

  const isNewArrivalsPage = searchParams.get('type');

  const [selectedTypeProduct, setSelectedTypeProduct] =
    useState<TypeProduct | null>(null);
  const [limit, setLimit] = useState<number>(10);
  const [total, setTotal] = useState<number>(10);
  const [dataProduct, setDataProduct] = useState([]);

  const { data: allTypeProducts } = useQuery(getAllTypeProduct, {
    variables: {
      page: 1,
      limit: 100,
    },
  });

  const [fetchData] = useLazyQuery(getDataProduct, {
    variables: {
      page: 1,
      limit: limit,
      typeProduct: typeProductParams?.type_id,
    },
    onCompleted: (data) => {
      if (!data) return;
      if (isNewArrivalsPage === 'new-arrivals') {
        setDataProduct(data?.getAllNewArrivals?.products);
        setTotal(data.getAllNewArrivals?.totalProducts);
      } else {
        setDataProduct(data?.getAllProducts?.products);
        setTotal(data.getAllProducts?.totalProducts);
      }
    },
  });

  const handleSelectCategory = (typeProduct: TypeProduct) => {
    setSelectedTypeProduct(typeProduct);
    if (isNewArrivalsPage === 'new-arrivals') {
      navigate(`/products/${typeProduct.id}?type=new-arrivals`);
    } else {
      navigate(`/products/${typeProduct.id}`);
    }
  };

  useEffect(() => {
    if (Object.keys(typeProductParams).length === 0 || !allTypeProducts) return;
    const findTypeProduct: TypeProduct =
      allTypeProducts.queryAllTypeProductPagination?.typeProducts?.find(
        (p: TypeProduct) => p.id === typeProductParams.type_id
      );
    if (findTypeProduct) {
      setSelectedTypeProduct(findTypeProduct);
    }
  }, [allTypeProducts, typeProductParams]);

  // Gọi lại truy vấn GraphQL khi typeProduct thay đổi
  useEffect(() => {
    fetchData(); // Gọi lại truy vấn GraphQL
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeProductParams, limit]); // Thêm typeProduct vào mảng dependencies của useEffect

  const handleIncreaseLimit = () => {
    setLimit((prevLimit) => prevLimit + 10); // Tăng 4 khi nhấn nút
  };

  return (
    <MainLayout>
      <Container className="category-top">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          sx={{ cursor: 'pointer' }}
        >
          <Box mr={width >= 744 ? 4 : 2}>
            <GoBackButton />
          </Box>
          <BreadCrumbComponent
            externalPath={['products', selectedTypeProduct?.name || '']}
          />
        </Stack>
        <Box mx={8.5}>
          <Typography
            variant={width > 430 ? (width > 1024 ? 'h3' : 'h2') : 'h4'}
            textAlign="left"
            className="title category-heading"
          >
            {isNewArrivalsPage === 'new-arrivals'
              ? 'New Arrivals'
              : 'Collections'}{' '}
            / {capitalizeWords(selectedTypeProduct?.name)}
          </Typography>
        </Box>
      </Container>
      <Stack
        className="category-container"
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gap={width >= 744 ? 4 : 0}
        mb={width >= 744 ? 4 : 2}
      >
        {allTypeProducts?.queryAllTypeProductPagination?.typeProducts?.map(
          (category: any, idx: number) => (
            <Typography
              key={idx}
              className={`category-item ${
                selectedTypeProduct?.name === category.name ? 'active' : ''
              }`}
              onClick={() => handleSelectCategory(category)}
            >
              {capitalizeWords(category.name)}
            </Typography>
          )
        )}
      </Stack>
      <Container maxWidth="oversize">
        <Grid container rowGap={8} columnSpacing={0.5} className="list-product">
          {dataProduct?.map((item: any, idx: number) => (
            <Grid item mobile={6} tablet={4} oversize={2.36} key={idx}>
              <ThumbnailProducts data={item} />
            </Grid>
          ))}
        </Grid>

        <Box
          textAlign={'center'}
          my={2}
          width={'100%'}
          display={dataProduct?.length >= total ? 'none' : 'block'}
        >
          <Box onClick={() => handleIncreaseLimit()}>
            <img src={images.arrowDown} alt="" style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </Container>
    </MainLayout>
  );
};
