import 'react-toastify/dist/ReactToastify.css';
import './index.css';

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AnimatePresence } from 'framer-motion';
import ProtectedRoute from './utils/Protect';
import ScrollToTop from './components/scrollToTop';
import { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { publicRoutes } from './router/Router';

function App() {
  return (
    <Suspense>
      <AnimatePresence>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {publicRoutes.map((route, index) => {
              const Page = route.component;
              let Element = <Page />;
              if (route.isPrivate) {
                Element = <ProtectedRoute>{Element}</ProtectedRoute>;
              }
              return <Route key={index} path={route.path} element={Element} />;
            })}

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </AnimatePresence>
      <ToastContainer />
    </Suspense>
  );
}
export default App;
