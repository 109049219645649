import './style.css';

import { Box, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

import ThumbnailGallery from '../../components/thumbnails/Gallery';
import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { FadeInSection } from '../../components/animations/FadeInSection';

export const GallerySection = ({
  data,
  handleIncreaseLimit,
  total,
}: {
  data: Array<any>;
  handleIncreaseLimit: Function;
  total: number;
}) => {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('desktop'));

  return (
    <FadeInSection>
      <Box className="collection-section">
        <Stack
          direction="column"
          alignItems="flex-start"
          mb={4}
          mx={isDownTablet ? '32px' : 0}
        >
          <Typography
            fontSize={(isDownTablet ? '48px' : '57px') + ' !important'}
            mb={2}
            className="title"
          >
            Gallery
          </Typography>
          <Typography>Tag us in your post to be featured</Typography>
        </Stack>
        <Grid mx="auto" maxWidth="oversize" container spacing={0}>
          {data?.map((item: any, idx: number) => (
            <Grid key={idx} item mobile={4} tablet={4} desktop={3}>
              <ThumbnailGallery
                data={{ ...item, idxItem: idx }}
                listData={data}
              />
            </Grid>
          ))}
        </Grid>
        <Box
          textAlign={'center'}
          my={2}
          width={'100%'}
          display={data?.length >= total ? 'none' : 'block'}
        >
          <Box onClick={() => handleIncreaseLimit()}>
            <img src={images.arrowDown} alt="" style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </Box>
    </FadeInSection>
  );
};
