import { Box, Button, ButtonBaseProps, Typography } from '@mui/material';
import { images } from '../../assets';

interface Props extends ButtonBaseProps {
  label: string;
}

const style = {
  exploreMoreContainer: {
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    padding: '8px 16px 12px 16px',
    color: 'var(--white)',
    alignItems: 'center',
    textDecoration: 'none !important',

    '& .arrow': {
      display: 'none',
    },
    '&:hover': {
      color: 'var(--secondary-01)',
      background: 'transparent',
    },
    '&:hover .arrow': {
      display: 'block',
      animation: 'slideRightFadeIn 0.3s',
      width: 24,
      height: 12,
      marginLeft: 0.5,
    },
    '&:not(:hover) .arrow': {
      animation: 'slideLeftFadeOut 0.3s',
    },
  },
};
export const ExploreMoreButton = (props: Props) => {
  return (
    <Button {...props} color="primary" sx={style.exploreMoreContainer}>
      <Typography variant="h5">{props.label}</Typography>
      <Box component="img" className="arrow" src={images.arrowLarge} alt="" />
    </Button>
  );
};
