const capitalizeWords = (text?: string) => {
  if (!text) return ''
  const words = text.split(" ");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
}

export { capitalizeWords }

export function formatVND(amount: any) {
  // Chuyển đổi số tiền thành chuỗi và tách phần nguyên và phần thập phân
  const parts = String(amount).split('.');
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? parts[1] : '';

  // Thêm dấu phẩy sau mỗi ba chữ số trong phần nguyên
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Định dạng cuối cùng của số tiền VND
  return `${integerPart}${decimalPart ? `,${decimalPart}` : ''}`;
}
