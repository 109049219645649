import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemIcon, ListItemText, Button } from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from 'react-router-dom';

import FeedbackIcon from '@mui/icons-material/Feedback';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import './drawerAdmin.css'
interface DrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}

const MENU = [
    {
        path: "/admin/products",
        name: "Products",
        icon: <ShoppingCartIcon />,
    },
    {
        path: "/admin/feedback",
        name: "Feedback",
        icon: <FeedbackIcon />,
    }
];

const drawerWidth = 300;

interface DrawerProps {
    open: boolean;
    toggleDrawer: () => void;
}

export const DrawerAdmin: React.FC<DrawerProps> = ({ open, toggleDrawer }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const handleLogout = ()=>{
        localStorage.removeItem('token')
        navigate('/admin')
    }
    return (
        <Drawer
            anchor="left"
            variant="permanent"
            open={open}
            onClose={toggleDrawer}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <List sx={{ marginTop: 5, overflow: 'auto' }}>
                {MENU.map((item, index) => (
                    <ListItem button component={Link} to={item.path} className={`itemMenu ${location.pathname === item.path ? "active" : ""}`} key={index} >
                        <ListItemIcon className="iconMenu">
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText className="textMenu" primary={item.name} />
                    </ListItem>
                ))}
            </List>
            <Button onClick={handleLogout} >Đăng xuất</Button>
            
            
        </Drawer>
    );
};