import './Drawer.css';

import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useMediaQuery,
} from '@mui/material';
import {
  DialogTypeProduct,
  DialogTypeProductRef,
} from '../../layout/DialogTypeProduct';
import { useRef, useState } from 'react';

import { images } from '../../assets';
import { navItems } from '../../constants';
import { theme } from '../../themes/Theme';
import { useNavigate } from 'react-router-dom';

export const HeaderDrawer = () => {
  const navigate = useNavigate();
  const dialogRef = useRef<DialogTypeProductRef>(null);
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const [open, setOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleOpenProductMenu = () => {
    dialogRef.current?.toggle();
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const handleClickToHome = () => {
    navigate('/');
  };

  const DrawerList = (
    <Box className="drawer-container" role="presentation">
      <IconButton
        component="div"
        sx={{ height: isDownTablet ? 30 : 40 }}
        onClick={handleClickToHome}
      >
        <img src={images.logo} height="100%" alt="logo" />
      </IconButton>
      <Stack>
        <List className="drawer-menu-container">
          {navItems.map((item, idx) => (
            <ListItem key={idx}>
              <ListItemButton
                onClick={
                  item.name === 'Products'
                    ? handleOpenProductMenu
                    : () => {
                        handleCloseDrawer();
                        navigate(item.path);
                      }
                }
              >
                <ListItemText className="menu-item" sx={{ textAlign: 'right' }}>
                  {item.name}
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box className="close-icon">
          <IconButton component="div" onClick={toggleDrawer}>
            <img src={images.close} alt="add" />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );

  return (
    <div>
      <IconButton
        component="div"
        className="toggleNavItems"
        onClick={toggleDrawer}
      >
        <img height="100%" src={images.plus} alt="add" />
      </IconButton>
      <Drawer className='drawer' anchor="top" open={open}>
        {DrawerList}
      </Drawer>
      <DialogTypeProduct ref={dialogRef} closeDrawer={handleCloseDrawer} />
    </div>
  );
};
