import './layout.css';

import {
  AppBar,
  Box,
  IconButton,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { DialogTypeProduct, DialogTypeProductRef } from './DialogTypeProduct';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { HeaderDrawer } from '../components/drawer/Drawer';
import { images } from '../assets';
import { navItems } from '../constants';
import { theme } from '../themes/Theme';

export const Header = () => {
  const navigate = useNavigate();
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const dialogRef = useRef<DialogTypeProductRef>(null);
  const [openNavItems, setOpenNavItems] = useState<boolean>(false);

  const toggle = () => {
    setOpenNavItems((prev) => !prev);
  };

  const handleClickToHome = () => {
    navigate('/');
  };

  const handleOpenProductMenu = () => {
    dialogRef.current?.toggle();
  };

  useEffect(() => {
    if (isDownTablet) {
      setOpenNavItems(false);
    }
  }, [isDownTablet]);

  return (
    <AppBar component="nav" className="navContainer">
      <Toolbar sx={{ padding: 0 }}>
        <IconButton
          component="div"
          sx={{ height: isDownTablet ? 30 : 40 }}
          onClick={handleClickToHome}
        >
          <img src={images.logo} height="100%" alt="logo" />
        </IconButton>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!isDownTablet ? (
            <Stack direction="row" alignItems="center" spacing={6}>
              {openNavItems ? (
                <Fragment>
                  {navItems.map((item, idx) => {
                    if (item.name === 'Products') {
                      return (
                        <Typography
                          key={idx}
                          onClick={handleOpenProductMenu}
                          className="navItem"
                        >
                          {item.name}
                        </Typography>
                      );
                    }
                    return (
                      <Link key={idx} to={item.path}>
                        <Typography key={idx} className="navItem">
                          {item.name}
                        </Typography>
                      </Link>
                    );
                  })}
                </Fragment>
              ) : null}
              <Box
                component="img"
                className={
                  openNavItems ? 'toggleNavItems open' : 'toggleNavItems'
                }
                src={images.plus}
                alt="add"
                onClick={toggle}
              />
            </Stack>
          ) : (
            <HeaderDrawer />
          )}
        </Box>
      </Toolbar>
      <DialogTypeProduct ref={dialogRef} />
    </AppBar>
  );
};
