import './style.css';

import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';

import { ButtonComponent } from '../../components/button';
import { CollectionSection } from './CollectionsSection';
import { GallerySection } from './GallerySection';
import { MainLayout } from '../../layout/MainLayout';
import { NewArrivalsSection } from './NewArrivalsSection';
import { getDataHomePage } from '../../graphql/queries';
import { theme } from '../../themes/Theme';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';

export const HomePage = () => {
  const navigate = useNavigate();
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));

  const [dataNewArrivals, setDataNewArrivals] = useState([]);
  const [dataCollections, setDataCollections] = useState([]);
  const [dataGallery, setDataGallery] = useState([]);

  const [limitCollections, setLimitCollections] = useState(6);
  const [limitGallery, setLimitGallery] = useState(8);

  const { data } = useQuery(getDataHomePage, {
    variables: {
      page: 1,
      limitGallery,
      limitNewArrivals: 12,
      limitTypeProduct: limitCollections,
    }, // Thay đổi giá trị của page và limit nếu cần
  });

  const handleIncreaseLimitGallery = () => {
    setLimitGallery((prevLimit) => prevLimit + 8); // Tăng 8 khi nhấn nút
  };

  const handleIncreaseLimitCollections = () => {
    setLimitCollections((prevLimit) => prevLimit + 6); // Tăng 6 khi nhấn nút
  };

  const handleClickShowNow = () => {
    navigate('/products');
  };

  useEffect(() => {
    if (data?.getAllNewArrivals?.products?.length > 0) {
      setDataNewArrivals(data?.getAllNewArrivals?.products);
    }
    if (data?.getAllGalery?.products?.length > 0) {
      setDataGallery(data?.getAllGalery?.products);
    }
    if (data?.queryAllTypeProductPagination?.typeProducts?.length > 0) {
      setDataCollections(data?.queryAllTypeProductPagination?.typeProducts);
    }
  }, [data]);

  return (
    <MainLayout>
      <Box className="banner">
        <Box className="wrapper">
          <Typography className="title heading">Taste the vibe</Typography>
          <Box className="content">
            <Typography variant="body1">
              At{' '}
              <span style={{ fontWeight: 600, fontStyle: 'italic' }}>
                Ly Glassware
              </span>
              , we offer a variety of glass products that are handcrafted,
              eco-friendly, and durable. Whether you are looking for wine
              glasses, vases, or jars, we have something for every occasion and
              taste.
            </Typography>
          </Box>
          <ButtonComponent
            style={{ maxWidth: 'max-content' }}
            size={isDownTablet ? 'medium' : 'large'}
            label="Shop now"
            onClick={handleClickShowNow}
          />
        </Box>
      </Box>
      <Stack
        className="second-section"
        direction="column"
        alignItems="stretch"
        justifyContent="center"
        gap={isDownTablet ? '100px' : 25}
      >
        <NewArrivalsSection data={dataNewArrivals} />
        <CollectionSection
          data={dataCollections}
          total={data?.queryAllTypeProductPagination?.totalTypeProducts}
          handleIncreaseLimit={handleIncreaseLimitCollections}
        />
        <GallerySection
          data={dataGallery}
          total={data?.getAllGalery?.totalProducts}
          handleIncreaseLimit={handleIncreaseLimitGallery}
        />
      </Stack>
    </MainLayout>
  );
};
