import './style.css';

import { Button, Container, Paper } from '@mui/material';
import { Category, Product } from '../../utils/type';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import AddIcon from '@mui/icons-material/Add';
import { AdminLayout } from '../../layout/AdminLayout';
import { DeleteDialog } from './Dialog/DialogAlert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ProductDialog } from './ProductDialog';
import { getAllProducts } from '../../graphql/queries';

export const AdminProductPage = () => {
  const { loading, data, refetch } = useQuery(getAllProducts, {
    variables: { page: 1, limit: 999 }, // Thay đổi giá trị của page và limit nếu cần
  });

  const [isDialogDeleteOpen, setIsDialogDeleteOpen] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');

  const [products, setProducts] = useState<Product[]>([]);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [editingProduct, setEditingProduct] = useState<any | null>(null);

  useEffect(() => {
    setProducts(data?.getAllProducts?.products || []);
  }, [data]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Tên sản phẩm', flex: 1 },
    { field: 'code', headerName: 'Mã sản phẩm', flex: 1 },
    { field: 'price', headerName: 'Giá gốc', flex: 1 },
    { field: 'priceSale', headerName: 'Giá giảm', flex: 1 },
    { field: 'height', headerName: 'Chiều cao', flex: 1 },
    { field: 'capacity', headerName: 'Dung tích', flex: 1 },
    {
      field: 'type',
      headerName: 'Loại sản phẩm',
      flex: 1,
      renderCell: (params) => <span>{params?.row?.type?.name}</span>,
    },
    {
      field: 'categories',
      headerName: 'Category',
      flex: 1.5,
      renderCell: (params) => (
        <span>
          {params?.row?.categories
            ?.map((category: Category) => category?.name)
            .join(', ')}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            startIcon={<EditIcon />}
            onClick={() => handleEditProduct(params.row as Product)}
          ></Button>
          <Button
            startIcon={<DeleteIcon />}
            onClick={() => handleDeleteProduct(params.id as string)}
          ></Button>
        </>
      ),
    },
  ];

  //add product
  const handleAddProduct = () => {
    setEditingProduct(null);
    setIsDialogOpen(true);
  };

  const handleEditProduct = (product: Product) => {
    const {
      id,
      name,
      price,
      priceSale,
      description,
      capacity,
      type,
      categories,
      link,
      code,
      image,
      radius,
      height,
    } = product;
    const categoriesNew = categories.map((item) => item.id);
    const products: any = {
      name,
      price,
      priceSale,
      description,
      capacity,
      link,
      code,
      image,
      type: type?.id,
      categories: categoriesNew,
      radius,
      height,
    };
    const idProduct = id;
    setEditingProduct({ idProduct, products });
    setIsDialogOpen(true);
  };

  const handleDeleteProduct = (id: string) => {
    setIsDialogDeleteOpen(true);
    setDeleteId(id);
  };
  if (loading) return <p>Loading...</p>;

  return (
    <AdminLayout>
      <Container className="product-page">
        <Paper sx={{ height: '100%', width: '100%' }}>
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddProduct}
            sx={{ margin: 2 }}
          >
            Thêm sản phẩm
          </Button>

          <ProductDialog
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
            editingProduct={editingProduct}
            refetchData={refetch}
          />
          <DeleteDialog
            open={isDialogDeleteOpen}
            onClose={() => setIsDialogDeleteOpen(false)}
            id={deleteId}
            refetchData={refetch}
          />
          {products.length > 0 ? (
            <DataGrid
              pagination
              rows={products}
              columns={columns}
              getRowId={(row) => row.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              sx={{
                overflowX: 'hidden',
                height: '100%', // Full height
                width: '100%', // Full width
                '& .MuiDataGrid-cell': {
                  color: 'black', // Text color for cells
                },
                '& .MuiTablePagination-root': {
                  color: 'black',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#910000', // Background color for header
                  color: 'white', // Text color for header
                },
                '& .MuiDataGrid-columnSeparator--sideRight': {
                  backgroundColor: '#910000',
                },
                '.MuiDataGrid-virtualScroller': {
                  height: '100% !important', // Ensure virtual scroller takes full height
                },
              }}
            />
          ) : (
            <div
              style={{
                height: '100%',
                display: 'flex',
                color: 'black',
                padding: '20px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              Chưa có sản phẩm nào
            </div>
          )}
        </Paper>
      </Container>
    </AdminLayout>
  );
};
