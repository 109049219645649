import { gql } from '@apollo/client';

export const getDataHomePage = gql`
  query getAllGalery(
    $page: Int!
    $limitNewArrivals: Int!
    $limitGallery: Int!
    $limitTypeProduct: Int!
  ) {
    getAllGalery(page: $page, limit: $limitGallery) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        height
        radius
        categories {
          id
          name
        }
        type {
          id
          name
        }
      }
      totalProducts
    }
    getAllNewArrivals(page: $page, limit: $limitNewArrivals) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        height
        radius
        categories {
          id
          name
        }
        type {
          id
          name
        }
      }
      totalProducts
    }
    queryAllTypeProductPagination(page: $page, limit: $limitTypeProduct) {
      typeProducts {
        id
        name
        image
        description
      }
      totalTypeProducts
    }
  }
`;

export const getDataProduct = gql`
  query getDataProduct($page: Int!, $limit: Int!, $typeProduct: ID) {
    getAllNewArrivals(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        link
        image
        priceSale
        description
        capacity
        price
        radius
        height
        type {
          id
          name
        }
      }
      totalProducts
    }
    getAllProducts(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        height
        radius
        type {
          id
          name
        }
      }
      totalProducts
    }
  }
`;

export const getAllTypeProduct = gql`
  query getAllTypeProduct($page: Int!, $limit: Int!) {
    queryAllTypeProductPagination(page: $page, limit: $limit) {
      typeProducts {
        id
        name
        image
      }
      totalTypeProducts
    }
  }
`;

export const getProductByType = gql`
  query getProductByType($page: Int!, $limit: Int!, $typeProduct: String!) {
    getAllNewArrivals(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        link
        image
        priceSale
        description
        capacity
        price
        height
        radius
      }
      totalProducts
    }
    getAllProducts(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        height
        radius
      }
      totalProducts
    }
    queryAllTypeProduct {
      id
      name
    }
  }
`;

export const getProductDetails = gql`
  query getProductByType(
    $page: Int!
    $limit: Int!
    $typeProduct: ID!
    $productId: ID!
  ) {
    getProductById(id: $productId) {
      id
      name
      code
      priceSale
      link
      image
      description
      capacity
      price
      type {
        id
        name
      }
      height
      radius
    }
    getAllNewArrivals(page: $page, limit: $limit) {
      products {
        id
        name
        code
        link
        image
        priceSale
        description
        capacity
        price
        radius
        height
        type {
          id
          name
        }
      }
      totalProducts
    }
    getAllProducts(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        type {
          id
          name
        }
        height
        radius
      }
      totalProducts
    }
  }
`;

export const getAllProducts = gql`
  query GetAllProducts($page: Int!, $limit: Int!, $typeProduct: ID) {
    getAllProducts(page: $page, limit: $limit, typeProduct: $typeProduct) {
      products {
        id
        name
        code
        priceSale
        link
        image
        description
        capacity
        price
        type {
          id
          name
        }
        height
        radius
        categories {
          id
          name
        }
      }
      totalProducts
    }
  }
`;

export const GetTypeProductAndCategories = gql`
  query GetTypeAndCategories {
    queryAllTypeProduct {
      id
      name
    }
    queryAllCategories {
      id
      name
    }
  }
`;

export const GetFeedBacks = gql`
  query GetFeedBacks($page: Int, $pageSize: Int) {
    getFeedbacks(page: $page, pageSize: $pageSize) {
      feedbacks {
        id
        email
        feedback
        createdAt
        updatedAt
      }
      totalCount
    }
  }
`;
