import './product-dialog.css'

import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, LinearProgress, MenuItem, OutlinedInput, Select, Stack, TextField, capitalize } from '@mui/material';
import { Category, Product, TypeProduct } from '../../utils/type'; // Adjust the import path as necessary
import { CreateProduct, UpdateProduct } from '../../graphql/mutations';
// ProductDialog.tsx
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import { GetTypeProductAndCategories } from '../../graphql/queries';
import InputField from '../../utils/Custom/textField';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import uploadImage from '../../utils/uploadImage';
import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';

interface ProductDialogProps {
    open: boolean;
    onClose: () => void;
    editingProduct?: any | null;
    refetchData?: any
}

export const ProductDialog: React.FC<ProductDialogProps> = ({
    open,
    onClose,
    editingProduct,
    refetchData
}) => {
    const { loading, error, data } = useQuery(GetTypeProductAndCategories, {
    });

    const [createProduct] = useMutation(CreateProduct);
    const [updateProduct] = useMutation(UpdateProduct);


    const dialogStyles = {
        width: '600px', // Dialog width
        height: '1000px', // Dialog height
        '& .MuiDialogContent-root': {
            overflowY: 'auto', // Adds scroll to content if needed
        },
        '& .MuiDialog-container .MuiPaper-root': (theme) => ({
            backgroundColor: `${theme.palette.common.white} !important`,
        }),
    };

    const [product, setProduct] = useState<Product>
        (getInitialProductState());
    const [typeProducts, setTypeProducts] = useState<TypeProduct[]>([])
    const [categories, setCategories] = useState<Category[]>([])
    const [loadingImage, setLoadingImage] = useState<boolean>(false)
    function getInitialProductState(): Product {
        return { name: '', code: '', price: 0, priceSale: 0, image: 'https://i.imgur.com/8nDvzwn.jpg', description: '', capacity: 0, type: { id: '', name: '' }, link: '', categories: [], height: 0, radius: 0 };
    }
    // Reset form when opening or closing the dialog, or when the editingProduct changes

    useEffect(() => {
        if (data?.queryAllTypeProduct?.length > 0) {
            setTypeProducts(data.queryAllTypeProduct)
        }

        if (data?.queryAllCategories?.length > 0) {
            setCategories(data.queryAllCategories)
        }
    }, [data])

    useEffect(() => {
        if (editingProduct) {
            setProduct(editingProduct?.products);
        } else {
            setProduct(getInitialProductState());
        }
    }, [editingProduct, open]);

    useEffect(() => {
        if (product.image) {
            setLoadingImage(false);  // Stop loading when image is set
        }
    }, [product.image]);

    const handleChange = (e: any) => {
        e.preventDefault()
        const { name, value } = e.target;
        let valueNew = value
        if (name === 'price' || name === 'priceSale') {
            valueNew = parseFloat(value)
        }

        if (name === 'capacity' || name === 'height' || name === 'radius') {
            valueNew = parseInt(value)
        }

        setProduct((prev) => ({ ...prev, [name]: valueNew }));
    };

    const handleImageChange = async (e: any) => {
        e.preventDefault();
        setLoadingImage(true)
        const file = e.target.files[0]
        const imageUrl = await uploadImage(file)
        console.log(imageUrl)
        await setProduct((prev) => ({
            ...prev, ['image']: imageUrl

        }))
        
        setLoadingImage(false)

    }


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        if (editingProduct) {
            try {
                const id = editingProduct.idProduct
                //update
                const { data } = await updateProduct({
                    variables: {
                        updateProductId: id,
                        input: product
                    }
                })
                if (data.updateProduct) {
                    toast.success('Cập nhật thành công')
                    refetchData()
                    onClose()
                }
            } catch (error) {
                toast.error(`Cập nhật thất bại, ${error}`)
                console.log("error", error)
            }

        } else {
            //create
            console.log("product", product)
            try {
                const { data } = await createProduct({
                    variables: {
                        input: product
                    }
                })
                if (data.createProduct !== null) {
                    toast.success('Tạo sản phẩm thành công')
                    refetchData()
                    onClose()
                }
            } catch (error: any) {
                if (error) {
                    toast.error(`Tạo sản phẩm thất bại!, ${error}`)
                }
            }
        }
    }

    return (
        <div style={{ background: 'white' }}>
            <Dialog open={open} onClose={onClose} PaperProps={{ sx: dialogStyles }}>
                <DialogTitle style={{ color: '#910000', fontSize: '40px', padding: '30px' }}>{editingProduct ? 'Chỉnh sửa sản phẩm' : 'Thêm sản phẩm'}</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <InputField
                            error
                            required
                            placeholder='Tên sản phẩm'
                            name="name"
                            value={product.name}
                            onChange={handleChange}
                        />
                        <InputField
                            required
                            placeholder='code'
                            name="code"
                            value={product.code}
                            onChange={handleChange}
                        />
                        <InputField
                            required
                            placeholder='Giá sản phẩm'
                            type='number'
                            name="price"
                            value={product.price}
                            onChange={handleChange}
                        />
                        <InputField
                            placeholder='Giá sản phẩm đã giảm'
                            type='number'
                            name="priceSale"
                            value={product.priceSale}
                            onChange={handleChange}
                        />
                        <InputField
                            required
                            placeholder='Dung tích'
                            name="capacity"
                            type='number'
                            value={product.capacity}
                            onChange={handleChange}
                        />
                        <InputField
                            required
                            type='number'
                            placeholder='Chiều cao'
                            name="height"
                            value={product.height}
                            onChange={handleChange}
                        />
                        <InputField
                            required
                            placeholder='Đường kính'
                            name="radius"
                            type='number'
                            value={product.radius}
                            onChange={handleChange}
                        />
                        <InputField
                            aria-label='Link'
                            placeholder='Link sản phẩm'
                            name="link"
                            value={product.link}
                            onChange={handleChange}
                        />
                        <FormControl fullWidth>
                            <FormLabel>Mô tả</FormLabel>
                            <TextField
                                className='textfield'
                                style={{ border: '1px solid #E5EAF2', marginBottom: '15px', color: 'black', borderRadius: '8px' }}
                                multiline
                                value={product.description}
                                name='description'
                                onChange={handleChange}
                                id="outlined-textarea"
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <FormLabel>Loại sản phẩm</FormLabel>
                            <Select
                                className='select-box'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={product.type}
                                name='type'
                                onChange={handleChange}
                                inputProps={{
                                    style: { color: 'black' } // Apply direct styling here
                                }}
                            >
                                {typeProducts.map((type, index) =>
                                    <MenuItem key={index} className='menu-item' value={type.id}>{type.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        <InputLabel id="category-select-label">Categories</InputLabel>
                        <Select
                            className='select-multi'
                            labelId="category-select-label"
                            id="category-select"
                            multiple
                            name='categories'
                            value={product.categories}
                            onChange={handleChange}
                            renderValue={(selected: any) => selected.map((cate) => categories.find((c) => c.id === cate).name).join(', ')}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    {category.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <Stack direction="column" alignItems="center" spacing={2} marginTop={2}>
                            <Button style={{ color: 'black' }} component="label" startIcon={<PhotoCamera />}>
                                Upload Image
                                <input type="file" hidden onChange={handleImageChange} accept="image/*" />
                            </Button>
                            {loadingImage ? (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                            ) : product.image && (
                                <img src={product.image} alt="Product" style={{ maxWidth: '300px', maxHeight: '100px' }} />
                            )}
                        </Stack>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose}>Hủy</Button>
                        <Button type="submit">{editingProduct ? 'Cập nhật' : 'Thêm'}</Button>
                    </DialogActions>
                </form>
                <ToastContainer />
            </Dialog>
        </div>

    );
};

