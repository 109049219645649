const routes = {
  home: '/',
  products: '/products',
  newArrivals: '/products?type=new-arrivals',
  category: '/products/:type_id',
  details: '/products/:type_id/:product_id',
  contact: '/contact',
  admin: '/admin',
  feedback:'/admin/feedback',
  adminProduct:'/admin/products',
};

const navItems = [
  {
    name: 'Home',
    path: routes.home,
  },
  {
    name: 'New Arrivals',
    path: routes.newArrivals,
  },
  {
    name: 'Products',
    path: routes.products,
  },
  {
    name: 'Contact',
    path: routes.contact,
  },
  // {
  //   name: 'Admin',
  //   path: routes.admin
  // },
  // {
  //   name: 'Admin Product',
  //   path: routes.adminProduct
  // },
  // {
  //   name: 'Feedback',
  //   path: routes.feedback
  // }
];

const categories = [
  'Cocktail',
  'Wine',
  'Flute',
  'Rock DOF',
  'Highball',
  'Tasting',
  'Mixology',
];

export { routes, navItems, categories };
