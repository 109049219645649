import React from 'react';
import { Navigate } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const isAuthenticated = () => {
    // Replace this logic with your actual authentication check
    return Boolean(localStorage.getItem('token'));
  };

  return isAuthenticated() ? <>{children}</> : <Navigate to="/admin" replace />;
};

export default ProtectedRoute;