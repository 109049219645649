import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollOptions: ScrollToOptions = {
      top: 0,
      behavior: 'smooth' // Thiết lập cuộn mượt mà
    };
    window.scrollTo(scrollOptions);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
