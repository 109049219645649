import './style.css';

import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { getAllTypeProduct, getDataProduct } from '../../graphql/queries';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BreadCrumbComponent } from '../../components/breadcrumb';
import { GoBackButton } from '../../components/button/GoBackButton';
import { MainLayout } from '../../layout/MainLayout';
import { ThumbnailProducts } from '../../components/thumbnails/Products';
import { capitalizeWords } from '../../utils';
import { images } from '../../assets';
import { useQuery } from '@apollo/client';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

export const ProductsPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const isNewArrivalsPage = searchParams.get('type');

  const [limit, setLimit] = useState(10);
  const [dataProduct, setDataProduct] = useState([]);
  const [total, setTotal] = useState(0);
  const [typeProductId, setTypeProductId] = useState('');

  const { loading, data } = useQuery(getDataProduct, {
    variables: { page: 1, limit: limit, typeProduct: typeProductId }, // Thay đổi giá trị của page và limit nếu cần
  });

  const { data: allTypeProducts } = useQuery(getAllTypeProduct, {
    variables: {
      page: 1,
      limit: 100,
    },
  });

  useEffect(() => {
    if (!data) return;
    if (isNewArrivalsPage === 'new-arrivals') {
      setDataProduct(data.getAllNewArrivals?.products);
      setTotal(data.getAllNewArrivals?.totalProducts);
    } else {
      setDataProduct(data.getAllProducts?.products);
      setTotal(data.getAllProducts?.totalProducts);
    }
  }, [data, isNewArrivalsPage]);

  const handleSelectCategory = (id: string) => {
    setTypeProductId(id);
    if (isNewArrivalsPage === 'new-arrivals') {
      navigate(`/products/${id}?type=new-arrivals`);
    } else {
      navigate(`/products/${id}`);
    }
  };

  if (loading) return <Typography>Loading...</Typography>;

  const handleIncreaseLimit = () => {
    setLimit((prevLimit) => prevLimit + 10); // Tăng 4 khi nhấn nút
  };

  return (
    <MainLayout>
      <Container className="product-top">
        <Stack
          direction="row"
          flexWrap="wrap"
          alignItems="flex-end"
          sx={{ cursor: 'pointer' }}
        >
          <Box mr={width >= 744 ? 4 : 2}>
            <GoBackButton />
          </Box>
          <BreadCrumbComponent />
        </Stack>
        <Box mx={3.5}>
          <Typography
            variant="h1"
            textAlign="left"
            className="title product-heading"
          >
            {isNewArrivalsPage === 'new-arrivals'
              ? 'New Arrivals'
              : 'Collections'}
          </Typography>
        </Box>
      </Container>
      <Stack
        className="category-container"
        direction="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        gap={width >= 744 ? 4 : 0}
        mb={width >= 744 ? 4 : 2}
      >
        {allTypeProducts?.queryAllTypeProductPagination?.typeProducts?.map(
          (category: any, idx: number) => (
            <Typography
              variant="body1"
              key={idx}
              className="category-item"
              onClick={() => handleSelectCategory(category.id)}
            >
              {capitalizeWords(category.name)}
            </Typography>
          )
        )}
      </Stack>
      <Box>
        <Grid container rowGap={8} columnSpacing={0.5} className="list-product">
          {dataProduct?.map((item: any, idx: number) => (
            <Grid item mobile={6} tablet={4} oversize={2.4} key={idx}>
              <ThumbnailProducts data={item} />
            </Grid>
          ))}
        </Grid>
        <Box
          textAlign={'center'}
          my={2}
          width={'100%'}
          display={dataProduct?.length >= total ? 'none' : 'block'}
        >
          <Box onClick={() => handleIncreaseLimit()}>
            <img src={images.arrowDown} alt="" style={{ cursor: 'pointer' }} />
          </Box>
        </Box>
      </Box>
    </MainLayout>
  );
};
