// imgurService.js
import axios from 'axios';
import { UPLOAD_URL } from './type';

const uploadImage = async (imageFile: File) => {
  const formData = new FormData();
  formData.append('uploaded_file', imageFile);
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await axios.post(UPLOAD_URL, formData, config);
    // return response.data.data.link; // Return the image URL
    return response.data.url
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error; // Rethrow the error to handle it in the component
  }
};

export default uploadImage;
