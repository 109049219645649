import './style.css';
import './popupInfoProduct.css';

import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { images } from '../../assets';
import { theme } from '../../themes/Theme';
import { useState } from 'react';

const styles = {
  card: {
    border: 'none',
    background: 'transparent',
    transition: 'all 0.1s ease-in-out',

    '& img': {
      transform: 'scale(1)',
      transition: 'all 0.1s ease-in-out',
    },
    '&:hover': {
      border: '1px solid var(--primary)',
      background: 'var(--neutral-black) !important',
    },
    '&:hover img': {
      transform: 'scale(0.89)',
    },
  },
};

interface Props {
  data: any;
  listData: Array<any>;
}

const PopUpInfoProduct = ({ data, listData }: Props) => {
  const isDownTablet = useMediaQuery(theme.breakpoints.down('tablet'));
  const isUpDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleSelectIndex = (index: number) => {
    if (index < 0 || index > listData.length - 1) return;
    setSelectedIndex(index);
  };

  const handleClickOpen = () => {
    setSelectedIndex(data.idxItem);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectedIndex(0);
    setOpen(false);
  };

  return (
    <>
      <Card sx={styles.card} onClick={handleClickOpen}>
        <CardActionArea>
          <CardMedia
            component="img"
            height={isDownTablet ? '206' : '400'}
            width="100%"
            image={data?.image}
            alt={data?.name || 'gallery'}
            loading="lazy"
          />
        </CardActionArea>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            position: 'relative',
            width: '100%',
            height: '100%',
            maxWidth: '1200px',
            maxHeight: isUpDesktop ? 800 : '100%',
            margin: 0,
            borderRadius: 0,
            border: 'none',
          },
        }}
      >
        <Box
          className="bg-arrow"
          sx={{
            position: 'absolute',
            top: isDownTablet ? '25%' : '50%',
            transform: 'rotate(180deg)',
            userSelect: 'none',
          }}
          onClick={() => handleSelectIndex(selectedIndex - 1)}
        >
          <img src={images.arrowRight} alt="" className="icon-white" />
          <img src={images.arrowNextBlack} alt="" className="icon-hover" />
        </Box>
        <Box
          className="bg-arrow"
          sx={{
            position: 'absolute',
            right: 0,
            top: isDownTablet ? '25%' : '50%',
            userSelect: 'none',
          }}
          onClick={() => handleSelectIndex(selectedIndex + 1)}
        >
          <img src={images.arrowRight} alt="" className="icon-white" />
          <img src={images.arrowNextBlack} alt="" className="icon-hover" />
        </Box>
        <DialogContent className="popup-info-product">
          <Card className="info-card">
            <Box className="close-icon" onClick={handleClose}>
              <IconButton aria-label="close">
                <Box component="img" width="24px" src={images?.close} alt="" />
              </IconButton>
            </Box>
            <CardMedia
              component="img"
              className="info-image"
              image={listData[selectedIndex]?.image}
              alt=""
            />
            <Stack
              className="info-content"
              direction="column"
              gap={2}
              bgcolor="var(--neutral-black)"
              height="100%"
            >
              <Stack direction="row" gap={1} alignItems="center">
                <img src={images.logo} width={50} height={50} alt="" />
                <Typography variant="h5">Ly.glassware</Typography>
              </Stack>
              <Stack direction="row" gap={1}>
                <Link
                  href={'https://www.facebook.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.facebook} alt="" /> 
                </Link>
                <Link
                  href={'mailto:Ly.glassware@gmail.com'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.mail} alt="" /> 
                </Link>
                <Link
                  href={'https://www.instagram.com/ly.glassware/'}
                  target="_blank"
                  sx={{ padding: 0 }}
                >
                  <img src={images.instagram} alt="" /> 
                </Link>
              </Stack>
              <Typography>{listData[selectedIndex]?.description}</Typography>
              <Typography>#lyglassware</Typography>
            </Stack>
          </Card>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PopUpInfoProduct;
