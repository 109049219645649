import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://api.lyglassware.com/graphql', // Thay YOUR_GRAPHQL_ENDPOINT bằng địa chỉ GraphQL API của bạn
  // uri: 'http://localhost:4444/graphql', // Thay YOUR_GRAPHQL_ENDPOINT bằng địa chỉ GraphQL API của bạn
});

const authLink = setContext((_, { headers }) => {
  // const token = 'YOUR_AUTH_TOKEN'; // Thay YOUR_AUTH_TOKEN bằng token của bạn
  return {
    headers: {
      ...headers,
      // Authorization: token ? `Bearer ${token}` : '',
      'Content-Type': 'application/json' // Thêm Content-Type vào header
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});




