import './style.css';

import { Button, ButtonProps } from '@mui/material';

import { ReactElement } from 'react';
import { images } from '../../assets';

interface Props extends ButtonProps {
  title?: string;
  height?: number;
  label: string | ReactElement;
  isMobile?: boolean;
}

export const ButtonComponent = ({
  label,
  height,
  isMobile,
  ...props
}: Props) => {
  return (
    <Button
      {...props}
      sx={{ height: height, borderRadius: '2px' }}
      variant="contained"
      color="primary"
      className={isMobile ? 'btn-mobile' : ''}
    >
      {label}
      <img className="icon" src={images.arrowLarge} alt="" />
    </Button>
  );
};
