import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import { formatVND } from '../../utils';
import { useNavigate } from 'react-router-dom';

interface Props {
  price: number;
  priceSale: number;
  name: string;
  id: string;
  code: string;
  image: string;
  type: {
    id: string;
  };
}

const styles = {
  card: {
    height: 300,
    width: '225px',
    position: 'relative',

    '& .overlay': {
      position: 'absolute',
      bottom: '0px',
      left: '0px',
      width: '100%',
      padding: '0px',
      height: '100px',
      transition: 'all 0.4s ease',
      backdropFilter: 'blur(4px)',
      opacity: '1',
    },
    '& .price': {
      width: '100%',
      padding: '10px 16px',
      color: 'var(--white)',
      backgroundColor: '#181818B2',
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
    },
    '& .bottom': {
      width: '100%',
      padding: '10px 16px',
      color: 'var(--white)',
      backgroundColor: '#F6EEE580',
      transition: 'padding 0.4s ease',
      opacity: '.8',
    },
    '&:hover .overlay': {
      height: '105px',
    },
    '&:hover .bottom': {
      height: '100%',
      padding: '12px 16px',
    },
    '&:hover .price': {
      color: 'var(--secondary-02)',
    },
  },
};

export const ThumbnailNewArrivals = (props: Props) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={styles.card}
      onClick={() => navigate(`/products/${props.type?.id}/${props.id}`)}
    >
      <CardActionArea>
        <CardMedia
          component="img"
          height="300"
          image={props?.image}
          alt="green iguana"
          loading="lazy"
        />
        <CardContent className="overlay">
          <Box className="price">
            <Typography
              variant="body1"
              color="GrayText"
              sx={{ textDecoration: `${props.priceSale !== 0 ? ' line-through' : 'interhit' }`, opacity: `${props.priceSale !== 0 ? '.6' : '1'}`, color:`${props?.priceSale !== 0 ? 'gray' : 'white'}` }}
              fontWeight={props.priceSale !== 0 ? 200 : 500}
            >
              {formatVND(props?.price)} đ
            </Typography>
            {props.priceSale !== 0 ? (<Typography variant="body1" fontWeight={500}>
              {formatVND(props.priceSale)} đ
            </Typography>): ''}
          </Box>
          <Box className="bottom">
            <Typography
              fontWeight={300}
              color="var(--neutral-darkGrey)"
              textTransform="uppercase"
              sx={{textOverflow:'ellipsis', overflow:'hidden',whiteSpace:'nowrap'}}
            >
              {props.name}
            </Typography>
            <p
              style={{
                opacity: 0.6,
                fontSize: '14px',
                color: 'var(--neutral-darkGrey)',
                fontWeight: 300,
                marginTop: '4px',
              }}
            >
              {props.code?.toUpperCase()}
            </p>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
